module.exports = {
    title: "OgilvyCZ",
    url: process.env.NEXT_PUBLIC_URL,
    baseUrl: "/",
    drupalBaseUrl: process.env.NEXT_INTERNAL_DRUPAL_API_URL,
    debug: process.env.CONSOLE_DEBUG,
    defaultLocale: "cs",
    locales: {
        en: "English",
        cs: "Česky",
    },
    translations_ns: [
        "common",
        "navbar",
        "news",
        "campaign",
        "article",
        "studie",
        "cookie",
        "microsite",
        "tender"
    ],
    cookie_consent_expire: 365,
    GTAG_CODE: {
        default: 'G-J4TS1RH322',
        performance: 'GTM-53BJXQ'
    }
}
